import styled from '@emotion/styled';
import React, { useState } from 'react';
import Joyride, { CallBackProps } from 'react-joyride';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useMount } from 'react-use';

import NavBar from './components/Navbar';
import { RequireAuth, useAuth } from './components/Providers/AuthProvider';
import { useJoyride } from './components/Providers/JoyrideProvider';
import Chat from './pages/Chat';
import FAQs from './pages/FAQs';
import Login from './pages/Login';
import Prompts from './pages/Prompts';
import { updateUser } from './services/users';
import { GuidedSteps } from './Types/guides';
import { User } from './Types/user';
import { MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { useFeatures } from './components/Providers/FeatureProvider';

const FullPageContainer = styled('div')({
  // padding: '24px',
  // These styles are because of the default Mui header responsiveness
  '@media (min-width: 0)': {
    height: 'calc(100vh - 56px)',
  },
  '@media (min-width: 600px)': {
    height: 'calc(100vh - 64px)',
  },
  overflowY: 'hidden',
  position: 'relative'
});

function ProvidedApp() {
  const [llm, setLLM] = useState<string>('ai-chat-gpt-4o');
  const { tourState, setTourState } = useJoyride();
  const { currentUser } = useAuth();
  const features = useFeatures();

  useMount(() => {
    if (!currentUser?.metadata?.tourCompleted) {
      setTourState({ ...tourState, ...{ steps: GuidedSteps } });
    }
  });

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const { lifecycle } = data;
    if (lifecycle === 'complete') {
      setTourState({ ...tourState, ...{ run: false, stepIndex: 0, tourActive: false } });
      await updateUser({ ...currentUser, ...{ metadata: { tourCompleted: true } } } as User);
    }
  };

  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={tourState.run}
        stepIndex={tourState.stepIndex}
        steps={tourState.steps}
        spotlightClicks={true}
        locale={{
          last: 'OK',
        }}
        styles={{
          buttonNext: {
            backgroundColor: '#181830',
            borderRadius: '0px',
          },
          options: {
            zIndex: 9999,
          },
        }}
      />
      <NavBar llm={llm} setLLM={setLLM} />
      <FullPageContainer>
        <div style={{
          position: 'absolute',
          top: '0',
          right: '20px',
          display: currentUser ? 'flex' : 'none',
          alignItems: 'center',
          background: 'white',
          zIndex: '10',
          borderRadius: '4px'
        }}>
          <Tooltip title={
            <Typography variant='caption'>
              The LLM selected will be used for all prompts and conversations
            </Typography>
          }
          placement='left'
          sx={{ width: '40px', height: '40px' }}
          >
            <Select
              value={llm}
              id='llm-select'
              onChange={(event) => {setLLM(event.target.value)}}
              sx={{
                '& > *': { fontFamily: 'Noto Sans' },
              }}
              renderValue={(value) => {
                switch(value) {
                  case 'ai-chat-gpt-4-32k':
                    return <Typography>GPT 4 32k</Typography>
                  case 'ai-chat-gpt-4':
                    return <Typography>GPT 4 Turbo</Typography>
                  case 'ai-chat-gpt-35-turbo-16k':
                    return <Typography>GPT 3.5 Turbo</Typography>
                  case 'ai-chat-gpt-4o':
                    return <Typography>GPT 4o</Typography>
                  default:
                    return <Typography>GPT 4 Turbo</Typography>
                }
              }}
              MenuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                MenuListProps: {disablePadding: true}
              }}
              size='small'
            >
              <MenuItem
                value={'ai-chat-gpt-35-turbo-16k'}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
              >
                GPT 3.5 Turbo
                <Typography style={{color: 'grey', fontSize: '14px' }}>
                  Quickest responses, smaller conversation limit; trained up to Sept 2021
                </Typography>
                <Typography style={{color: 'grey', fontSize: '12px'}}>
                  (GPT 3.5 Turbo 16k tokens)
                </Typography>
              </MenuItem>
              {
                features.gpt4_32k && (
                  <MenuItem
                    value={'ai-chat-gpt-4-32k'}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
                  >
                    GPT 4 32k
                    <Typography style={{color: 'grey', fontSize: '14px' }}>
                      Larger conversation limit, smarter answers, longer response times; trained up to April 2023
                    </Typography>
                    <Typography style={{color: 'grey', fontSize: '12px'}}>
                      (GPT 4 32k tokens)
                    </Typography>
                  </MenuItem>
                )
              }
              <MenuItem
                value={'ai-chat-gpt-4'}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
              >
                GPT 4 Turbo
                <Typography style={{color: 'grey', fontSize: '14px' }}>
                  Largest conversation limit, smartest answers, longest response times; trained up to April 2023
                </Typography>
                <Typography style={{color: 'grey', fontSize: '12px'}}>
                  (GPT 4 Turbo 128k tokens)
                </Typography>
              </MenuItem>
              <MenuItem
                value={'ai-chat-gpt-4o'}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
              >
                GPT 4o
                <Typography style={{color: 'grey', fontSize: '14px' }}>
                  The latest GPT release, quick response times, trained up to October 2023
                </Typography>
                <Typography style={{color: 'grey', fontSize: '12px'}}>
                  (GPT 4o 128k tokens)
                </Typography>
              </MenuItem>
            </Select>
          </Tooltip>
        </div>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/chat"
            element={
              <RequireAuth>
                <Chat llm={llm} />
              </RequireAuth>
            }
          />
          <Route
            path="/chat/:conversationId"
            element={
              <RequireAuth>
                <Chat llm={llm} />
              </RequireAuth>
            }
          />
          <Route
            path="/prompts"
            element={
              <RequireAuth>
                <Prompts publishedOnly={true} />
              </RequireAuth>
            }
          />
          <Route
            path="/my-prompts"
            element={
              <RequireAuth>
                <Prompts publishedOnly={false} />
              </RequireAuth>
            }
          />
          <Route
            path="/faqs"
            element={
              <RequireAuth>
                <FAQs />
              </RequireAuth>
            }
          />
          <Route path="*" element={<Navigate to="/chat" replace={true} />} />
        </Routes>
      </FullPageContainer>
    </>
  );
}

export default ProvidedApp;
