import { MenuOpen, Send } from '@mui/icons-material';
import {
  Alert,
  Container,
  Drawer,
  IconButton,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FunctionName } from 'Types/enums';

import ChatBubbleComponent from '../components/Chat/ChatBubble';
import ChatHistory from '../components/Chat/ChatHistory';
import ChatInfo from '../components/Chat/ChatInfo';
import LimitWarning from '../components/Chat/LimitWarning';
import { MessageFrom } from '../enums';
import {
  getConversation,
  getConversations,
  getMessages,
  postConversation,
  postMessage,
} from '../services/conversation';
import { findPrompt } from '../services/prompts';
import { Conversation, Message } from '../Types/conversation';
import { Prompt } from '../Types/prompt';

const ChatPageContainer = styled('div')({
  display: 'flex',
  gap: '10px',
  height: 'inherit',

  div: {
    position: 'relative',
  },
});

type ContainerProps = {
  open: boolean;
};

const drawerWidth = '350px';

const DrawerContainer = styled('div')<ContainerProps>(({ open }) => ({
  width: open ? drawerWidth : 'auto',
}));

const MainContainer = styled('div')<ContainerProps>(({ open }) => ({
  marginLeft: open ? 'auto' : `-${drawerWidth}`,
  width: `100%`,
  display: 'flex',
  justifyContent: 'center',
  zIndex: 1,
  alignItems: 'flex-start',
  backgroundColor: 'white',
  height: 'inherit'
}));

const StyledChatContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 'auto',
  marginRight: 'auto',
  justifyContent: 'space-between',
  minHeight: '100%',

  '& > *:not(:last-child)': {
    textAlign: 'center',
  },
});

const StyledAlert = styled(Alert)({
  marginBottom: '10px',
  marginTop: '10px',
  backgroundColor: '#D2D2DF',
  color: 'black',
  '.MuiAlert-icon': { display: 'flex', alignItems: 'center' },
});

const ChatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const ChatRow = styled('div')({
  display: 'flex',
  gap: '5px',
  marginBottom: '16px',
  alignItems: 'flex-end',
  position: 'sticky',
  bottom: '0',
  paddingTop: '10px',
});

const SendButton = styled(IconButton)({
  height: '40px',
  width: '40px',
});

const IconContainer = styled('span')({
  color: 'gray',
  transition: 'all 0.2s ease-in-out',
  display: 'flex',
  height: '20px',
  position: 'absolute',
  top: '20px',
  left: '20px',

  span: {
    color: 'black',
    marginLeft: '5px',
  },
});

export type ChatProps = {
  llm: string;
};

const Chat = ({ llm }: ChatProps): JSX.Element => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState<Message[]>([]);
  const [shouldFetchMessages, setShouldFetchMessages] = useState<boolean>(false);
  const [shouldFetchConversations, setShouldFetchConversations] = useState<boolean>(true);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [isLoadingResponse, setIsLoadingResponse] = useState<boolean>(false);
  const { conversationId } = useParams();
  const [currentConversationId, setCurrentConversationId] = useState<string>();
  const [currentConversation, setCurrentConversation] = useState<Conversation>();
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const [promptId, setPromptId] = useState<string>();
  const [prompt, setPrompt] = useState<Prompt>();
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState<boolean>(
    window.matchMedia('(min-width: 800px)').matches
  );
  const [functions, setFunctions] = useState<FunctionName[]>([]);
  const [useFunctions, setUseFunctions] = useState<boolean>(false);
  // const [showFunctions, setShowFunctions] = useState<boolean>(
  //   localStorage.getItem('showFunctions') === 'true'
  // );
  const [tokens, setTokens] = useState<number>(0);
  const [maxTokens, setMaxTokens] = useState<number>(80000);
  const [showChatInfo, setShowChatInfo] = useState<boolean>(false);
  const [greyAllMessages, setGreyAllMessages] = useState<boolean>(false);

  // These three functions are hacks to get around costly rerenders for this page
  // this monolithic page should be refactored into smaller pieces
  const setChatPrompt = (prompt: string) => {
    if (inputRef.current) {
      inputRef.current.value = prompt;
    }
  };

  const getChatPrompt = () => {
    if (inputRef.current) {
      return inputRef.current.value;
    }
    return '';
  };

  const handleSubmit = useCallback(
    async (prompt?: Prompt) => {
      if (inputRef.current) {
        const ref = inputRef.current;
        if (ref.value === '') {
          setShowErrorMessage(true);
          setTimeout(() => {
            setShowErrorMessage(false);
          }, 5000);
          return;
        }

        setIsLoadingResponse(true);
        const message = getChatPrompt();
        ref.value = '';
        if (!conversationId) {
          const newConversationTitle = prompt ? prompt.title : message;
          if (!prompt) {
            setMessages([
              ...messages,
              {
                conversationId: '',
                message,
                from: MessageFrom.USER,
                createdAt: '',
                id: '',
                deleted: false,
                tokens: 0,
              },
            ]);
          }
          const newConversation = await postConversation({
            title: newConversationTitle,
            promptId: searchParams.get('prompt') ?? undefined,
            functions: [...functions, ...(prompt?.functions ?? [])],
          });
          await postMessage({ conversationId: newConversation.id, message: message }, llm);
          setShouldFetchConversations(true);
          if (window.location.pathname === '/chat') {
            navigate(`/chat/${newConversation.id}`);
          }
        } else {
          setGreyAllMessages(true);
          setMessages([
            ...messages,
            {
              conversationId,
              message,
              from: MessageFrom.USER,
              createdAt: '',
              id: '',
              deleted: false,
              tokens: 0,
            },
          ]);
          await postMessage({ conversationId, message: message }, llm);
          setShouldFetchMessages(true);
        }
      }
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    [conversationId, llm, messages, navigate, searchParams, functions]
  );

  // Triggers when a new prompt is selected
  // updates promptId
  useEffect(() => {
    const newPromptId = searchParams.get('prompt');
    if (newPromptId) {
      setPromptId(newPromptId);
    }

    // const shouldShowFunctions = searchParams.get('enable-functions');
    // if (shouldShowFunctions) {
    //   localStorage.setItem('showFunctions', 'true');
    //   setShowFunctions(true);
    // }
  }, [searchParams]);

  //Triggers when the user triggers a submit, changes the navigation, changes the prompt, or changes the promptId
  //fetches the prompt, sets prompt and functions
  useEffect(() => {
    if (promptId && !prompt) {
      const fetchPrompt = async () => {
        const prompt = await findPrompt(promptId);

        setPrompt(prompt);
        setChatPrompt(prompt.prompt);
        setUseFunctions(prompt.functions && prompt.functions.length > 0 ? true : false);
        setFunctions(prompt.functions ?? []);

        if (!prompt.userEditRequired) {
          await handleSubmit(prompt);
        }
      };
      fetchPrompt();
    }
  }, [handleSubmit, navigate, prompt, promptId]);

  useEffect(() => {
    if (currentConversationId && currentConversation && shouldFetchMessages) {
      const fetchMesages = async () => {
        try {
          const newMessages = await getMessages(currentConversationId);
          if (
            currentConversation?.prompt &&
            currentConversation.prompt.userEditRequired === false
          ) {
            if (newMessages.length > 0) {
              newMessages.shift();
            }
          }
          setMessages(newMessages);
          // set tokens based on the most recent message in the conversation where the ai is the sender
          const mostRecentAIMessages = newMessages.filter(
            (message) => message.from === MessageFrom.AI
          );
          setTokens(
            mostRecentAIMessages.length > 0
              ? mostRecentAIMessages[mostRecentAIMessages.length - 1].tokens
              : 0
          );
          setIsLoadingResponse(false);
          setShouldFetchMessages(false);
        } catch (e) {
          enqueueSnackbar('Error fetching messages', { variant: 'error' });
          navigate('/chat');
        }
      };
      fetchMesages();
    }
  }, [currentConversation, currentConversationId, enqueueSnackbar, navigate, shouldFetchMessages]);

  useEffect(() => {
    if (currentConversationId !== conversationId) {
      const fetchConversation = async () => {
        if (conversationId) {
          const newConversation = await getConversation(conversationId);
          setCurrentConversationId(conversationId);
          setCurrentConversation(newConversation);

          let newFunctions = newConversation.functions ?? [];
          setFunctions(newFunctions);
          newFunctions = Array.from(
            new Set(newFunctions.concat(newConversation?.prompt?.functions ?? []))
          );

          setUseFunctions(newFunctions.length > 0 ? true : false);
          setShouldFetchMessages(true);
        } else {
          setCurrentConversationId(conversationId);
          setCurrentConversation(undefined);
          setFunctions([]);
          setUseFunctions(false);
        }
      };
      fetchConversation();
    }
  }, [conversationId, currentConversation, currentConversationId]);

  useEffect(() => {
    if (shouldFetchConversations) {
      const fetchConversations = async () => {
        const newConversations = await getConversations();
        setConversations(newConversations);
        setShouldFetchConversations(false);
        setGreyAllMessages(false);
      };
      fetchConversations();
    }
  }, [setShouldFetchConversations, shouldFetchConversations]);

  const handleConversationUpdate = (conversationId?: string) => {
    setPrompt(undefined);
    setPromptId(undefined);
    setShouldFetchConversations(true);
    if (conversationId) {
      navigate(`/chat/${conversationId}`);
    } else {
      navigate('/chat');
    }
  };

  useEffect(() => {
    if (currentConversationId && !conversationId) {
      setMessages([]);
      setChatPrompt('');
      setTokens(0);
    }
  }, [conversationId, currentConversationId]);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [messages, currentConversationId]);

  useEffect(() => {
    switch (llm) {
      case 'ai-chat-gpt-4':
        setMaxTokens(80000);
        break
      case 'ai-chat-gpt-4-32k':
        setMaxTokens(32000);
        break
      case 'ai-chat-gpt-4o':
        setMaxTokens(128000);
        break
      default:
        setMaxTokens(16000);
        break;
    }
  }, [llm]);  

  useEffect(() => {
    setShowChatInfo(window.location.pathname === '/chat');
  }, [navigate]);

  // Function to handle the selecting of function checkboxes
  // const updateFunctions = useCallback(
  //   async (funcNames: FunctionName[]) => {
  //     setFunctions(funcNames);
  //     await updateConversation({ functions: funcNames }, conversationId ?? '');
  //   },
  //   [conversationId]
  // );

  return (
    <ChatPageContainer>
      <DrawerContainer open={historyDrawerOpen}>
        <Drawer
          variant="persistent"
          anchor="left"
          open={historyDrawerOpen}
          sx={{ height: '100%', width: drawerWidth }}
        >
          <ChatHistory
            conversations={conversations}
            handleConversationUpdate={handleConversationUpdate}
            closeHistoryDrawer={() => setHistoryDrawerOpen(false)}
          />
        </Drawer>
      {historyDrawerOpen ? null : (
        <Tooltip
          sx={{ marginTop: '0', position: 'absolute', zIndex: 2 }}
          title={<Typography variant="caption"> Open History Panel </Typography>}
        >
          <IconContainer onClick={() => setHistoryDrawerOpen(true)}>
            <MenuOpen sx={{ transform: 'rotate(180deg)', cursor: 'pointer' }} />
          </IconContainer>
        </Tooltip>
      )}
      </DrawerContainer>
      <MainContainer open={historyDrawerOpen}>
        <div
          style={{ overflow: 'auto', width: '100%', minHeight: '100%', height: '100%' }}
          ref={messageContainerRef}
        >
          <StyledChatContainer maxWidth="md">
            <div style={{ position: 'sticky', zIndex: '999', marginBottom: '10px', top: '0' }}>
              <StyledAlert icon={`🔍`}>
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  <Typography sx={{ fontSize: '14px', fontWeight: '500', textAlign: 'left' }}>
                    Do not use Beacon for math or numbers, double check outputs for inaccuracies, and review and revise all communications prior to using.
                  </Typography>
                </div>
              </StyledAlert>
            </div>
            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '20px',
              }}
            >
              <ChatInfo
                currentConversation={currentConversation}
                currentPrompt={prompt}
                shouldShowInfo={showChatInfo}
                useFunctions={useFunctions}
              />
            </div>
            <ChatContainer>
              {messages.length === 0 && !prompt?.id && (
                <ChatBubbleComponent
                  key={'placeholder'}
                  title='Start a conversation with me 😊'
                  message={{
                    from: MessageFrom.AI,
                    message:
                      'Type a question or command in the box or select an old conversation to the left!',
                  }}
                  loading={shouldFetchMessages}
                />
              )}
              {messages.length === 0 && prompt && prompt.userEditRequired && (
                <>
                  <ChatBubbleComponent
                    key={'placeholder'}
                    message={{
                      from: MessageFrom.AI,
                      message: `Looks like you're using a prompt, make sure to edit it if necessary before sending.`,
                    }}
                    loading={shouldFetchMessages}
                  />
                </>
              )}
              {messages.map((message) => (
                <ChatBubbleComponent
                  key={message.id}
                  message={message}
                  loading={shouldFetchConversations || (shouldFetchMessages && !greyAllMessages)}
                />
              ))}
              {isLoadingResponse && (
                <ChatBubbleComponent
                  key={'loading'}
                  message={{ from: MessageFrom.AI }}
                  loading={true}
                />
              )}
              <ChatRow>
                <div style={{ paddingBottom: '20px', flexGrow: 1 }}>
                  <TextField
                    inputRef={(ref) => (inputRef.current = ref)}
                    variant="outlined"
                    label="Please enter a message"
                    InputProps={{ style: { fontSize: '16px' } }}
                    multiline
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={showErrorMessage}
                    autoFocus
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSubmit(prompt);
                      }
                    }}
                    autoComplete="off"
                  />
                  <LimitWarning percentage={tokens / maxTokens} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    height: '100%',
                    paddingBottom: 'calc(20px + 0.4rem)',
                  }}
                >
                  <Tooltip title={<Typography variant="caption"> Send </Typography>}>
                    <SendButton onClick={() => handleSubmit(prompt)} color="primary">
                      <Send />
                    </SendButton>
                  </Tooltip>
                </div>
              </ChatRow>
            </ChatContainer>
          </StyledChatContainer>
        </div>
      </MainContainer>
    </ChatPageContainer>
  );
};

export default Chat;
